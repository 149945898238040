.root {
  display: flex;
  margin-top: 10px;
  padding-bottom: 20px;
  margin-right: 20px;
  margin-left: 17px;
}

.avatar {
  margin-right: 15px;
}

.form {
  width: 100%;

  button {
    margin-top: 10px;
  }
}

.PostLink {
  width: calc(50% - 0.5px);
  text-decoration: none;
  color: black;
  height: 176px;
  padding: 40px;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    background: #fafafa;
  }
}

.spanLink {
  font-size: 12px;
  color: #7b7b7b;
  line-height: 16px;
  margin-bottom: 8px;
}
