.plus {
  width: 100%;
  margin: 80px auto 64px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.11px;
  line-height: 24px;

  &:hover {
    opacity: 0.65;
  }
}
