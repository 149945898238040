.title {
  input {
    font-size: 42px;
    font-weight: 900;
  }

  div {
    &:before,
    &:after {
      display: none;
    }
  }
}

.title2 {
  input {
    font-size: 24px;
    font-weight: 400;
  }

  div {
    &:before,
    &:after {
      display: none;
    }
  }
}

.image {
  margin-top: 20px;
  width: 100%;
  border-radius: 10px;
}

.tags {
  margin: 15px 0;
}

.editor {
  margin: 30px -30px;

  :global {
    .cm-s-easymde {
      border: 0;
      font-size: 22px;
    }
    .editor-toolbar {
      border: 0;
      background-color: rgb(0 0 0 / 2%);

      .no-disable,
      .separator:nth-last-child(2n) {
        display: none;
      }
    }
  }
}

.buttons {
  display: flex;

  button {
    margin-right: 15px;
  }
}
